<template>
<div class="white">
    <v-row justify="center" align="center" class="text-center">
        <v-container>
            <v-avatar size="200" tile>
                <v-container>
                    <v-img :src="$config.logo" />
                </v-container>
            </v-avatar>
            <h2 class="mt-2" style="color: #122940">
                {{ $config.title }}
            </h2>
            <h2 class="mt-2" style="color: #122940">
                منظومة سير العمل الإداري
            </h2>
            <h3 class="mt-2" style="color: #0080ff">|| مسعى ||</h3>
        </v-container>
    </v-row>
    <br />
    <br />
    <Loading v-if="$global.state.loading" />
    <div v-if="!$global.state.loading">

        <v-alert color="secondary" text outlined border="left">
            <v-row align="center">
                <h4 class="blacko--text mr-2">

                    <!-- الإجراء الحالي : ( ) -->
                </h4>
 
                <v-spacer />
                <v-btn color="primary" outlined @click="$router.go(-1)">
                    <v-icon> navigate_next </v-icon>
                    <h3>رجوع</h3>
                </v-btn>
            </v-row>
        </v-alert>

        <v-card outlined>
            <v-row class="pa-3">
                <v-col cols="12" lg="6" md="6" class="pa-2">
                    <h4 class="mb-2">
                        اسم مقدم الطلب
                        <span class="required">*</span>
                    </h4>
                    <v-text-field v-model="visiter.name" placeholder="اسم مقدم الطلب" filled outlined hide-details="auto" required />
                </v-col>
                <!-- <v-col cols="12" lg="6" md="6" class="pa-2">
                    <h4 class="mb-2">
                        ملاحظات
                    </h4>
                    <v-text-field v-model="visiter.description" placeholder="الوصف" filled outlined hide-details="auto" required />
                </v-col> -->
                <v-col cols="12">
                    <v-btn v-if="!items.length" :disabled="visiter.name?false:true" @click="getVisiteor()" outlined color="primary">
                        التالي
                    </v-btn>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid">
                <v-container fluid>
                    <v-row>

                        <v-col v-for="(field, orderDataIndex) in items" :key="orderDataIndex" :cols="field.cols">
                            <div v-if="field.field.fieldType.name == 'ZainCash' &&
                                    orderData[orderDataIndex] != undefined
                                    ">
                                <ZainCash :field="field.field" :formId="orderData[orderDataIndex].formId" :rules="field.rules" :title="field.title" :isRequired="field.isRequired" :items="field.items" :oldData="field.oldData" />
                            </div>
                            <div v-if="field.field.fieldType.name == 'Large File'">
                                <large-file :isRequired="field.isRequired" :field="field" v-model="orderData[orderDataIndex].data" :rules="field.isRequired == true ? [$global.state.required()] : []" :index="orderDataIndex" />
                            </div>
                            <!-- file start -->
                            <!-- <div v-if="field.field.fieldType.name == 'File' || field.field.fieldType.name == 'Image'">
                                <v-card outlined>
                                    <v-card-title>
                                        <h4 class="mb-2">
                                            {{ field.field.title }}
                                            <span class="required" v-if="field.isRequired">*</span>
                                        </h4>
                                        <v-spacer></v-spacer>

                                        <v-btn color="primary" class="text-none mr-2 btn" :loading="$global.state.uploading" @click="onButtonClick(orderDataIndex)" large>
                                            <v-icon left>cloud_upload</v-icon>
                                            <h3>{{ field.field.title }}</h3>
                                        </v-btn>
                                        <input :ref="`uploader${orderDataIndex}`" class="d-none" :rules="field.isRequired ? [$global.state.required()] : []" type="file" accept="jpg, jpeg, png, mp4, mp3, pdf, doc, docx, xls, xlsx, ppt, pptx, txt, zip, rar" @change="(e) => onFileChanged(e, orderDataIndex)" />
                                        <v-text-field :readonly="$route.params.actionType == 'view'" name="name" label="label" id="id" class="d-none" v-model="orderData[orderDataIndex].data" :rules="field.isRequired ? [$global.state.required()] : []" />
                                    </v-card-title>
                                    <v-card-text>
                                        <div v-if="field.field.fieldType.name == 'File'">
                                            <div v-for="file in orderData[orderDataIndex].data" :key="file.name">
                                                <v-card outlined>
                                                    <v-card-title>
                                                        {{ file.name }}
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="error" @click="orderData[orderDataIndex].data = orderData[orderDataIndex].data.filter((f) => f !== file)">
                                                            <v-icon>close</v-icon>
                                                        </v-btn>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-img v-if="file" :src="URL.createObjectURL(file)"></v-img>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </div>
                                        <v-img v-else-if="field.field.fieldType.name == 'Image' && orderData[orderDataIndex].data" :src="orderData[orderDataIndex].data ? URL.createObjectURL(orderData[orderDataIndex].data) : ''" contain></v-img>
                                        <v-alert color="secondary" text outlined :value="true">
                                            <div class="d-flex align-center">
                                                <v-icon color="primary">mdi-information-outline</v-icon>
                                                <h4 class="mx-2" style="padding: 0">
                                                    <span class="font-weight-bold">
                                                        {{ $t("allowed-files") }}:
                                                    </span>
                                                    jpg, jpeg, png, mp4, mp3, pdf, doc, docx, xls, xlsx,
                                                    ppt, pptx, txt, zip, rar.</h4>
                                            </div>
                                        </v-alert>
                                    </v-card-text>

                                </v-card>
                            </div> -->

                            <!-- text box start -->
                            <div v-if="field.field.fieldType.name == 'text box'">
                                <h4 class="mb-2">
                                    {{ field.field.title }}
                                    <span class="required" v-if="field.isRequired">*</span>
                                </h4>
                                <!-- im here -->
                                <v-text-field :readonly="$route.params.actionType == 'view'" v-model="orderData[orderDataIndex].data" :rules="[
                                            field.isRequired == true
                                                ? $global.state.required()
                                                : true,
                                            field.field.max
                                                ? $global.state.maxLength(
                                                    field.field.title,
                                                    field.field.max
                                                )
                                                : true,
                                            field.field.min
                                                ? $global.state.minLength(
                                                    field.field.title,
                                                    field.field.min
                                                )
                                                : true,
                                        ]" :placeholder="field.field.title" filled outlined hide-details="auto" required :hint="field.field.note" :counter="field.field.length ? field.field.length : false" />
                            </div>
                            <!-- text box end -->

                            <!-- Check box start -->
                            <div v-if="field.field.fieldType.name == 'Check box'">
                                <h4 class="mb-2">
                                    {{ field.field.title }}
                                    <span class="required" v-if="field.isRequired">*</span>
                                </h4>
                                <div class="d-flex">
                                    <v-checkbox v-for="check in field.field.value.split(',')" :rules="field.isRequired == true
                                            ? [$global.state.required()]
                                            : []
                                            " :key="check" :label="check" class="mx-1" @change="(e) => {
        let arr =
            [orderDataIndex].data.split(',');
        if (e) {
            arr.push(check);
        } else {
            arr.splice(arr.indexOf(check), 1);
        }
        orderData[orderDataIndex].data =
            arr.length == 1 ? check : arr.join(',');
        if (orderData[orderDataIndex].data[0] == ',')
            orderData[orderDataIndex].data =
                orderData[orderDataIndex].data.substr(1);
        log(orderData[orderDataIndex].data);
    }
        " />
                                    <span>{{ field.field.note }}</span>
                                </div>
                            </div>
                            <!-- Check box end -->

                            <!-- Single select start -->
                            <div v-if="field.field.fieldType.name == 'Single select'">
                                <h4 class="mb-2">
                                    {{ field.field.title }}
                                    <span class="required" v-if="field.isRequired">*</span>
                                </h4>
                                <select-field :title="field.field.title" :items="field.field.value.split(',')" v-model="orderData[orderDataIndex].data" :isRequired="field.isRequired" :multiple="false" />

                                <span>{{ field.field.note }}</span>
                            </div>
                            <!-- Single select end -->

                            <!-- Multiple select start -->
                            <div v-if="field.field.fieldType.name == 'Multiple select'">
                                <h4 class="mb-2">
                                    {{ field.field.title }}
                                    <span class="required" v-if="field.isRequired">*</span>
                                </h4>
                                <select-field :title="field.field.title" :items="field.field.value.split(',')" v-model="orderData[orderDataIndex].data" :isRequired="field.isRequired" :multiple="true" />

                                <span>{{ field.field.note }}</span>
                            </div>
                            <!-- Multiple select end -->

                            <!-- text area start -->
                            <div v-if="field.field.fieldType.name == 'text area'">
                                <h4 class="mb-2">
                                    {{ field.field.title }}
                                    <span class="required" v-if="field.isRequired">*</span>
                                </h4>
                                <v-textarea :readonly="$route.params.actionType == 'view'" v-model="orderData[orderDataIndex].data" :rules="[
                                            field.isRequired == true
                                                ? $global.state.required()
                                                : true,
                                            field.field.max
                                                ? $global.state.maxLength(
                                                    field.field.title,
                                                    field.field.max
                                                )
                                                : true,
                                            field.field.min
                                                ? $global.state.minLength(
                                                    field.field.title,
                                                    field.field.min
                                                )
                                                : true,
                                        ]" :value="field.data" :placeholder="field.field.title" filled outlined hide-details="auto" required :hint="field.field.note" :counter="field.field.length ? field.field.length : false" />
                            </div>
                            <!-- text area end -->

                            <!-- Date and time start -->
                            <div v-if="field.field.fieldType.name == 'Date and time'">
                                <h4 class="mb-2">
                                    {{ field.field.title }}
                                    <span class="required" v-if="field.isRequired">*</span>
                                </h4>
                                <datefield v-model="orderData[orderDataIndex].data" :rules="field.isRequired == true ? [$global.state.required()] : []
                                        " />

                                <span>{{ field.field.note }}</span>
                            </div>
                            <!-- Date and time end -->

                            <!-- Html editor start -->
                            <div v-if="field.field.fieldType.name == 'Html editor'">
                                <h4 class="mb-2">
                                    {{ field.field.title }}
                                    <span class="required" v-if="field.isRequired">*</span>
                                </h4>
                                <VueEditor dir="ltr" v-model="orderData[orderDataIndex].data" :rules="field.isRequired == true ? [$global.state.required()] : []
                                        " />

                                <span>{{ field.field.note }}</span>
                            </div>
                            <!-- Html editor end -->

                            <!-- Signature start -->
                            <div v-if="field.field.fieldType.name == 'Signature'">
                                <h4 class="mb-2">
                                    {{ field.field.title }}
                                    <span class="required" v-if="field.isRequired">*</span>
                                </h4>
                                <signature-pad v-model="orderData[orderDataIndex].data" :index="orderDataIndex" />

                                <span>{{ field.field.note }}</span>
                            </div>
                            <!-- Signature end -->
                            <!-- Multiple Files start -->

                            <div v-if="field.field.fieldType.name == 'Multiple Files'">
                                <h4 class="mb-2">
                                    {{ field.field.title }}
                                    <span class="required" v-if="field.isRequired">*</span>
                                </h4>
                                <MultipleFiles v-model="orderData[orderDataIndex].data" :index="orderDataIndex" :field="field" />

                                <span>{{ field.field.note }}</span>
                            </div>

                            <!-- Multiple Files end -->
                            <!-- {{field.field.fieldType.name}} -->
                            <!-- Table start -->
                            <div v-if="field.field.fieldType.name == 'Table'">
                                <v-row align="center" class="ma-2">
                                    <h4 class="mb-2">
                                        {{ field.field.title }}
                                        <span class="required" v-if="field.isRequired">*</span>
                                    </h4>
                                    <v-spacer />
                                    <v-btn @click="openAddDialog(field)" color="primary">
                                        <h3>{{ $t("idhafh-byanat") }}</h3>
                                    </v-btn>
                                </v-row>
                                <v-divider />
                                <v-data-table :items="tableItems.find((v) => v.tableId == field.id).items" :headers="tableItems.find((v) => v.tableId == field.id).headers
                                        " hide-default-footer :no-data-text="$t('no-data')">
                                    <template v-for="tableField in tableItems.find(
                                            (v) => v.tableId == field.id
                                        ).fields" v-slot:[`item.${tableField.id}`]="{ item }">
                                        <div :key="tableField.id" v-if="tableField.field.fieldType.name == 'Check box attend'
                                                " class="d-flex">
                                            <v-checkbox @change="(e) => {
                                                    submitDate(field, row, tableField.id, e);
                                                }
                                                    " v-bind:false-value="0" v-bind:true-value="1" color="primary" :disabled="!isTheEnd" />
                                            <v-text-field :readonly="$route.params.actionType == 'view'" :value="item[tableField.id]" disabled dir="ltr" />
                                        </div>
                                        <div :key="tableField.id" v-if="tableField.field.fieldType.name != 'Check box attend'
                                                ">
                                            {{ item[tableField.id] }}
                                        </div>
                                    </template>
                                    <!-- <template v-slot:[`item.${}`]="{ item, orderDataIndex }" v-if="isTheEnd">
                      </template> -->

                                    <!-- <template v-slot:item.actions="{ item, index }">
                                            <v-tooltip bottom="">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn @click="deleteItem(field, index, item, orderDataIndex)" v-on="on"
                                                        icon :disabled="isTheEnd">
                                                        <v-icon color="error"> delete </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t("hthf") }}</span>
                                            </v-tooltip>

                                            <v-tooltip bottom="">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn @click="openEditDialog(field, item, index)" v-on="on" icon
                                                        :disabled="isTheEnd">
                                                        <v-icon color="info"> edit </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t("edit") }}</span>
                                            </v-tooltip>
                                        </template> -->
                                </v-data-table>
                                <v-divider />

                                <span>{{ field.field.note }}</span>
                            </div>
                            <!-- Table end -->
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
        <Dialog />
        <v-card outlined class="mt-2">
            <v-container fluid>
                <h3 v-if="!valid">
                    {{ $t("yjb-alyk-mle-alhqwl-almtlwbh") }}
                    <span class="red--text">*</span>
                    {{ $t("llantqal-llkhtwh-altalyh") }}
                </h3>
                <div>
                    <v-btn v-if="visiterData" @click="postOrderData()" :disabled="!valid" :loading="saveLoading" dark large class="btn" color="primary" style="border-radius: 0px; border: solid">
                        <h3>حفظ التحديث</h3>
                    </v-btn>
                </div>
            </v-container>
        </v-card>
    </div>
</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import * as scanner from "scanner.js";
import SignaturePad from "@/components/fields/SignaturePad.vue";
import datefield from "@/components/fields/datefield.vue";
import SelectField from "@/components/fields/SelectField.vue";
import LargeFile from "@/components/fields/LargeFile.vue";
import MultipleFiles from "@/components/fields/MultipleFiles.vue";
import ZainCash from "@/components/fields/ZainCash.vue";
// import MultipartFileUpload from "@/components/MultipartFileUpload.vue";
export default {
    components: {
        VueEditor,
        Dialog: () => import("@/views/blackList/dialog"),
        SelectField,
        datefield,
        SignaturePad,
        LargeFile,
        MultipleFiles,
        ZainCash,
    },
    created() {
        // this.getItems();
        
        this.data
    },
    mounted() {
        this.overDataId = this.$route.params.id;
        console.log(this.overDataId, "this is curret=n t routet");
        this.$eventBus.$on("save-item", (items) => {
            let table = this.tableItems.find(
                (v) => v.tableId == this.$store.state.itemDetails.id
            );
            let inOrderData = this.orderData.find(
                (v) => v.fieldId == this.$store.state.itemDetails.field.id
            );
            let obj = {
                row: table.items[table.items.length - 1] ?
                    table.items[table.items.length - 1].row + 1 : 0,
            };
            items.forEach((item) => {
                obj[item.fieldChildId] = item.data;
                inOrderData.orderDataChildren.push({
                    data: Array.isArray(item.data) ? item.data.join(",") : item.data,
                    fieldChildId: item.fieldChildId,
                    row: obj.row,
                    sequence: 0,
                    orderDataId: inOrderData.id
                });
            });
            table.items.push(obj);
        });
        this.$eventBus.$on("edit-item", (items) => {
            let table = this.tableItems.find(
                (v) => v.tableId == this.$store.state.itemDetails.id
            );
            let row = table.items.find((v) => v.row == items.row);
            let inOrderData = this.orderData.find(
                (v) => v.fieldId == this.$store.state.itemDetails.field.id
            );
            Object.keys(row).forEach((key) => {
                if (key != "row") {
                    // console.log(row[key]);
                    let col = inOrderData.orderDataChildren.find(
                        (v) => v.fieldChildId == key && v.row == items.row
                    );
                    let d = items.items.find((v) => v.fieldChildId == key).data;
                    let d2 = Array.isArray(d) ? d.join(",") : d;
                    console.log(d2);
                    col.data = d2;
                    row[key] = d2;
                    this.$forceUpdate();
                }
            });
        });
        // const storedValue = (localStorage.getItem("orderData"));
        // if (storedValue) {
        //     this.orderData = JSON.parse(storedValue)
        //     console.log(this.orderData, "this is oreder data")
        // }
    },
    methods: {
        async getVisiteor() {
            this.$global.state.loading = true;
            try {
                const res = await this.$http.post("Order/Visitor", {
                    name: this.visiter.name,
                    description: this.visiter.description,
                    workflowId: this.overDataId
                });
                this.visiterData = res.data.result;
                // this.visiterData = res.data.result.nodeId;
                console.log(this.visiterData)
                this.getItems()
                this.getOrderNode()
            } catch (err) {
                console.log(err);
                this.$service.faild(err.response.data.message);
            } finally {
                this.$global.state.loading = false;
            }
        },
        // saveDataOfline() {
        //     localStorage.setItem("orderData", JSON.stringify(this.orderData));
        // },

        scanToJpg() {
            scanner.scan(this.displayImagesOnPage, {
                output_settings: [{
                    type: "return-base64",
                    format: "jpg",
                }, ],
            });
        },
        displayImagesOnPage(successful, mesg, response) {
            if (!successful) {
                // On error
                console.error("Failed: " + mesg);
                return;
            }
            if (
                successful &&
                mesg != null &&
                mesg.toLowerCase().indexOf("user cancel") >= 0
            ) {
                // User canceled.
                console.info("User canceled");
                return;
            }
            var scannedImages = scanner.getScannedImages(response, true, false); // returns an array of ScannedImage
            for (
                var i = 0; scannedImages instanceof Array && i < scannedImages.length; i++
            ) {
                var scannedImage = scannedImages[i];
                this.processScannedImage(scannedImage);
            }
        },
        processScannedImage(scannedImage) {
            this.imagesScanned.push(scannedImage);
        },
        async submitDate(item, row, index, checked) {
            let tableData = this.tableItems
                .find((v) => v.tableId == item.id)
                .items.find((v) => v.row == row);
            if (checked) {
                tableData[index] = new Date().toLocaleString();
            } else {
                tableData[index] = null;
            }
            let inOrderData = this.orderData.find((v) => v.fieldId == item.field.id);
            let col = inOrderData.orderDataChildren.find(
                (v) => v.fieldChildId == index && v.row == row
            );
            col.data = tableData[index];
            await this.$http.post(
                "/OrderDataChild/attend/" +
                item.orderData.orderDataChildren.find(
                    (v) => v.fieldChild.field.fieldType.name == "Check box attend"
                ).id
            );
            this.$forceUpdate();
        },
        renameKeys(obj, newKeys) {
            const keyValues = Object.keys(obj).map((key) => {
                const newKey = newKeys[key] || key;
                return {
                    [newKey]: obj[key],
                };
            });
            return Object.assign({}, ...keyValues);
        },
        log(e) {
            console.log(e);
        },
        changeFile(index) {
            this.orderData[index].data = this.$global.state.path;
        },
        onFileChanged(e, index) {
            // do something
            this.handleFileUpload({
                file: e.target.files[0],
                endPoint: "UploadFile",
                index: index,
            });
        },
        onButtonClick(index) {
            // this.isSelecting = true
            window.addEventListener(
                "focus",
                () => {
                    // this.isSelecting = false
                }, {
                    once: true,
                }
            );
            console.log(this.$refs[`uploader` + index][0].click());
            // this.$refs[`uploader` + index].click();
        },
        async handleFileUpload({
            file,
            endPoint,
            index
        }) {
            return new Promise((resolve, reject) => {
                this.$global.state.uploading = true;
                try {
                    let formData = new FormData();
                    formData.append("files", file);
                    this.$http
                        .post(endPoint, formData)
                        .then((res) => {
                            this.$global.state.path = res.data.fileList[0].path;
                            this.orderData[index].data = res.data.fileList[0].path;
                            this.$service.success(this.$t("upload-success"));
                            resolve();
                        })
                        .catch((err) => {
                            console.log(err);
                            this.$service.faild(err.response.data.message);
                            reject();
                        })
                        .finally(() => {
                            this.$global.state.uploading = false;
                        });
                } catch (err) {
                    console.log(err);
                    this.$service.faild(err.response.data.message);
                    reject();
                } finally {
                    this.$global.state.uploading = false;
                }
            });
        },
        openAddDialog(item) {
            item.dialogType = "add";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields");
        },
        openEditDialog(field, item, index) {
            field.dialogType = "edit";
            this.$store.commit("setItemDetails", {
                ...field,
                row: index,
            });
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields", item);
        },

        deleteItem(field, rowIndex, item, orderDataIndex) {
            this.tableItems.find((v) => v.tableId == field.id).items.splice(rowIndex, 1);
            this.orderData[orderDataIndex].orderDataChildren = this.orderData[orderDataIndex].orderDataChildren.filter(x => x.row != item.row)
        },

        async updateOrderData() {
            this.saveLoading = true;
            console.log(this.orderData)
            try {
                await this.$http.post(`OrderData/Range`, this.orderData);
                this.$service.success();
                this.$router.push({
                    path: `/checked`,
                });
                // this.$router.go(-1)
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message);
            } finally {
                this.saveLoading = false;

            }
        },
        // Separate function for handling signature field
        async handleSignatureField(item) {
            const fieldData = this.orderData.find((x) => x.fieldId === item.field.id);
            if (fieldData.data !== "" && fieldData.data !== null) {
                const arr = fieldData.data.split(",");
                const mime = arr[0].match(/:(.*?);/)[1];
                const bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                const file = new Blob([u8arr], {
                    type: mime
                });
                const signatureFile = new File([file], "Signature.png", {
                    type: "image/png",
                });
                await this.handleFileUpload({
                    file: signatureFile,
                    endPoint: "UploadFile",
                    index: this.orderData.findIndex((x) => x.fieldId === item.field.id),
                });
            }
        },
        // Separate function for handling multiple select field
        handleMultipleSelectField(item) {
            const fieldData = this.orderData.find((x) => x.fieldId === item.field.id);
            if (Array.isArray(fieldData.data)) {
                fieldData.data = fieldData.data.join(",");
            }
        },
        // Main function for posting order data
        async postOrderData(nextMainNodeId, statusType, actionId) {
            this.saveLoading = true;
            // Handling multiple select and signature fields
            for await (const item of this.items) {
                if (item.field.fieldType.name === "Multiple select") {
                    this.handleMultipleSelectField(item);
                }
                if (item.field.fieldType.name === "Signature") {
                    this.handleSignatureField(item);
                }
            }
            const data = {
                nextMainNodeId,
                // mainNodeId: this.$route.params.mainNodeId,
                mainNodeId: this.visiterData.nodeId,
                orderId: this.visiterData.id,
                statusId: this.getStatusId(statusType),
                // userId: this.$store.state.user.id,
                orderDatas: this.orderData,
            };

            // if (this.$store.state.user.role === "Guest") {
            //     data.statusId = "8605ae2f-ae5e-4dd9-81c0-113e82b5e166";
            // }
            console.log(data,"orderData data")
            try {
                await this.$http.post("OrderData/Range", data);
                // if (this.$store.state.user.role === "Guest") {
                //     this.$router.push("/guest");
                // } else {
                //     this.$router.push("/orders");
                // }
                this.$service.success();
            } catch (err) {
                console.log(err);
            } finally {
                this.saveLoading = false;
            }
        },
        headers(field) {
            let headers = [];
            field = field.field;
            // field.fieldChildren.sort((a, b) => a.sequence - b.sequence);
            for (let i = 0; i < field.fieldChildren.length; i++) {
                if (
                    headers.filter((v) => v.value == field.fieldChildren[i].id).length ==
                    0
                ) {
                    headers.push({
                        text: field.fieldChildren[i].field.name,
                        value: field.fieldChildren[i].id,
                        sequence: field.fieldChildren[i].sequence,
                    });
                }
            }
            headers.push({
                text: "",
                value: "actions",
            });
            return headers;
        },
        async getOrderNode() {
            this.$global.state.loading = true;
            try {
                // let GroupIds = [];
                // if (this.$store.state.user.user.userGroups.length > 0) {
                //     await this.$store.state.user.user.userGroups.forEach(element => {
                //         GroupIds.push(element.groupId);
                //     });
                // }
                let url = `OrderNode?OrderId=${this.visiterData.id}&IsDeleted=false&Sort=desc&PageIndex=1&PageSize=100`;
                // if (!this.isReport) {
                //     for (let j = 0; j < GroupIds.length; j++) {
                //         url = url + `&GroupIds=${GroupIds[j]}`;
                //     }
                // }
                const res = await this.$http.get(url);
                this.orderNode = res.data.result;
                console.log(this.orderNode[0],"this is orderNode data")
            } catch (err) {
                console.log(err);
            } finally {
                this.$global.state.loading = false;
            }
        },
        async getItems() {
            
            this.$global.state.loading = true;
            try {
                const res = await this.$http.get(
                    `Form?NodeId=${this.visiterData.nodeId}`
                );
                this.items = res.data.result;
                // sort by sequence
                this.items = this.items.sort((a, b) => a.sequence - b.sequence);
                // await 
                // this.getActions();
console.log(res.data.result,"this is count")
                for (let i = 0; i < res.data.count; i++) {
                    if (!Array.isArray(this.orderData)) {
                        this.orderData = []
                    }
                    
                    this.orderData.push({
                        // id: this.$route.params.actionType == 'add' ? '' : res.data.result[i].orderData.id,
                        data: res.data.result[i].orderData != null ? res.data.result[i].orderData.data : res.data.result[i].field.value,
                        nodeId: this.visiterData.nodeId,
                        fieldId: res.data.result[i].field.id,
                        // orderNodeId: this.$route.params.orderNodeId,
                        orderNodeId: this.orderNode[0].id,
                        workflowId: this.overDataId,
                        formId: res.data.result[i].id,
                        orderDataChildren: [],
                    });
                    if (this.items[i].field.fieldType.name == "Table") {
                        if (this.tableItems.filter((v) => v.tableId == this.items[i].id)) {
                            this.tableItems.push({
                                tableId: this.items[i].id,
                                items: [],
                                fields: this.items[i].field.fieldChildren
                                // OrderDataId: res.data.result[i].orderData.id
                            });
                        }
                        this.setTableData(this.items[i]);
                        let table = this.tableItems.find(
                            (x) => x.tableId == this.items[i].id
                        );
                        table.headers = this.headers(this.items[i]);
                        table.headers.sort((a, b) => a.sequence - b.sequence);
                    }
                }
                        console.log(this.orderData,"dksdjshdshdshdjhsjdsdjsjdhjsdhs")

            } catch (err) {
                console.log(err);
            } finally {
                this.$global.state.loading = false;
            }
        },
        async setTableData(item) {
            if (item.orderData != null) {
                let inOrderData = this.orderData.find(
                    (v) => v.fieldId == item.field.id
                );
                let table = this.tableItems.find((x) => x.tableId == item.id);
                item.orderData.orderDataChildren.sort((a, b) => a.row - b.row);
                for (let i = 0; i < item.orderData.orderDataChildren.length; i++) {
                    let data = item.orderData.orderDataChildren[i];
                    if (table.items.filter((v) => v.row == data.row).length == 0) {
                        table.items.push({
                            row: data.row,
                        });
                    }
                    inOrderData.orderDataChildren.push({
                        data: Array.isArray(data.data) ? data.join(",") : data.data,
                        fieldChildId: data.fieldChild.id,
                        row: data.row,
                        sequence: 0,
                        id: data.id,
                        orderDataId: data.orderDataId
                    });
                    table.items.find((v) => v.row == data.row)[data.fieldChild.id] =
                        data.data;
                }
            }
        },
        getStatusId(statusType) {
            console.log("statusType");
            console.log(statusType);
            switch (statusType) {
                case 1:
                    return this.$global.state.status.find((x) => x.key == "inprogress").id;
                case 2:
                    return this.$global.state.status.find((x) => x.key == "rejected").id;
                case 3:
                    return this.$global.state.status.find((x) => x.key == "completed").id;
                case 4:
                    return this.$global.state.status.find((x) => x.key == "waitAccept").id;
                default:
                    return this.$global.state.status.find((x) => x.key == "inprogress").id;
            }
        },
        async getActions() {
            this.$global.state.loading = true;
            try {
                const res = await this.$http.get(
                    `Action?MainNodeId=${this.$route.params.mainNodeId}&IsDeleted=false&PageIndex=1&PageSize=100`
                    // &OrderId=${this.$route.params.orderId}
                );
                this.actions = res.data.result;
                let end = this.actions.find((x) => x.type == 3);
                if (end) {
                    this.isTheEnd = this.actions.find((x) => x.type == 3).type == 3;
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.$global.state.loading = false;
            }
        },
        // signture end
    },
    data() {
        return {
            visiter: {
                name: null,
                description: null,
                workflowId: null
            },
            visiterData: null,
            overDataId: null,
            orderNode:null,
            valid: true,
            isTheEnd: false,
            currentRowIndex: 0,
            tableItems: [],
            dwtObj: null,
            scanIndex: 0,
            tableItemsPost: [],
            currentTableFieldId: "",
            orderData: [],
            indexData: null,
            successful: false,
            row: 0,
            imagesScanned: [],
            modal: [false],
            actions: [],
            date: "",
            saveLoading: false,
            content: "",
            items: [],
            colors: ["#9e0142", "#f46d43", "#fdae61", "#3288bd", "#5e4fa2", "#AD1357", "#283593", "#00695C", "#00838F", "#01579B", "#455A64", "#424242", "#D84315", "#5D4037", "#388E3C", "#C2185B"],
        };
    },
    beforeDestroy() {
        this.$eventBus.$off("fill-fields");
        this.$eventBus.$off("save-item");
    },
    // this is edit 
    // watch: {
    //     orderData: {
    //         handler: function () {
    //             this.saveDataOfline()
    //         },
    //         deep: true
    //     }
    // },

};
</script>
<style scoped>
.head {
  display: flex;
  justify-content: center;
  gap: 1rem;
  border-radius: 0px 0px 36px 36px;
  padding: 1rem;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #ddd !important;
    color: rgb(189, 189, 189) !important;
}
